<template>
  <div id="findings-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-secondary mb-5 mr-3" to="/findings">Zurück zur Übersicht</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Make Customer Journey Module as main unit with Link Tree Pages as Journey steps</li>
      <li>Be able to name Steps and connect them into one Journey with Channels in the beginning and KPIs at the end</li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
      <li>Connect to <router-link to="/links">Link Tree</router-link></li>
      <li>Connect to <router-link to="/kpis">KPI Tools</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
    </ul>-->

    <div v-if="step === 0" class="card">
      <div class="card-header">
        <h3>Finding hinzufügen</h3>
      </div>
      <div class="card-body">
        <p>
          Hast du die Erkenntnis oder das Problem auf einer bestimmten Seite erkannt? <br>
          Wenn ja, wähle zuerst die Seite aus, mit der du das Finding verknüpfen willst.
        </p>
        <p>
          Wenn du mehrere Findings auf einmal eintragen willst, probiere doch einfach das
          <router-link to="/audits">Audits</router-link>
          -Feature aus, um gleich gesamte
          <router-link to="/journeys">Customer Journeys</router-link>
          abzubilden.
        </p>
        <NodeSelector :exclude-items="['findings']"
                      :nodes="newNodes"
                      node-size="col-sm-6 col-lg-3"
                      :selected-node-url="selectedNodeUrl"
                      @selectNode="selectNode($event)"
        ></NodeSelector>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" @click="step++">Weiter</button>
      </div>
    </div>

    <div v-if="step === 1" class="card">
      <div class="card-header">
        <h3>{{ selectedFindingNode.node.title }}</h3>
        <p><code>{{ selectedFindingNode.node.path || selectedFindingNode.node.url }}</code></p>
        <NodeLinks :exclude-items="['findings']" :expanded="true" :node="selectedFindingNode.node"></NodeLinks>
      </div>
      <div class="card-body border-bottom">
        <h4>Deine ausgewählte Seite</h4>
        <div class="row">
          <div class="col-sm-6 col-lg-8">
            <FindingsImage :adding="true"
                           :findings="selectedFindingNode.findings"
                           :node="selectedFindingNode.node"
                           :size="size"
                           @addFinding="addFinding($event)"
                           @findingEnter="highlightFinding($event, true)"
                           @findingLeave="highlightFinding($event, false)"
            ></FindingsImage>
          </div>
          <div class="col-sm-6 col-lg-4">
            <h4>Bereits gesammelte Findings findest du hier.</h4>
            <p>
              Füge deine neue Erkenntnis einfach hinzu, indem du links auf das Bild klickst.
            </p>
            <ul class="list-unstyled">
              <li v-for="(audit, index) in Object.keys(selectedFindingNode.audits)
                            .sort((a,b) => a.id === 'standalone' ? 1 : b.id === 'standalone' ? -1 : 0)"
                  :key="index"
              >
                <h5 v-if="selectedFindingNode.audits[audit].audit.id !== 'standalone'" class="mt-4">
                  <router-link :to="'/audits/edit/' + selectedFindingNode.audits[audit].audit.id">
                    <i class="fad fa-stethoscope"></i>
                    {{ selectedFindingNode.audits[audit].audit.title }}
                  </router-link>
                </h5>
                <h5 v-else class="mt-4">Unabhängige Findings:</h5>
                <FindingsList :findings="selectedFindingNode.audits[audit].findings" :highlighted-finding-id="highlightedFindingId"></FindingsList>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import FindingsImage from "@/components/FindingsImage";
import NodeSelector from "@/components/NodeSelector";
import NodeLinks from "@/components/NodeLinks";
import FindingsList from "@/components/FindingsList";

export default {
  name: 'FindingsEdit',
  components: {
    FindingsList,
    NodeLinks,
    FindingsImage,
    NodeSelector
  },
  data() {
    return {
      step: 0,
      search: "",
      selectedNodeUrl: "",
      highlightedFindingId: "",
      newNode: {
        id: this.generateId(),
        url: "Platzhalter",
        title: "Leerer Schritt (füge hier eigene Seiten hinzu)",
        image: {
          thumb: "",
          desktop: "",
          tablet: "",
          mobile: ""
        }
      }
    }
  },
  computed: {
    findings() {
      return this.project.findings
          .filter(finding => !this.activeTheoryId || finding.theoryId === this.activeTheoryId)
          .map(finding => {
            finding.node = this.findNode(finding.nodeUrl);
            if (finding.auditId) {
              finding.audit = this.project.audits.find(audit => audit.id === finding.auditId);
            }
            return finding;
          });
    },
    nodeFindings() {
      let nodeFindings = {};
      this.findings.forEach(finding => {
        let nodeFinding = nodeFindings[finding.nodeUrl];

        if (nodeFinding) {
          nodeFinding.findings.push(finding)
        } else {
          nodeFindings[finding.nodeUrl] = {
            node: finding.node ? finding.node : { url: finding.nodeUrl, title: finding.nodeUrl },
            findings: [finding]
          }
        }
      });
      return nodeFindings;
    },
    nodeFindingsNodes() {
      return Object.values(this.nodeFindings).map(nf => nf.node);
    },
    newNodes() {
      let searchNode = node => {
        let isSearched = this.search === "";
        if (!isSearched) {
          for (let prop of ['url', 'title']) {
            if (node[prop].indexOf(this.search) >= 0) {
              isSearched = true;
            }
          }
        }
        return isSearched;
      }
      return [this.newNode]
          .concat(this.project.netNodes.filter(searchNode))
          .concat(this.project.externalNodes.filter(searchNode));
    },
    selectedFindingNode() {
      if (!this.selectedNodeUrl) return;

      let auditFindings = {};
      let node = this.nodeFindings[this.selectedNodeUrl].node;
      let nodeFindings = this.nodeFindings[this.selectedNodeUrl].findings;

      nodeFindings.forEach(finding => {
        if (!finding.auditId || !finding.audit) {
          finding.audit = {
            id: "standalone",
            title: "Standalone",
          }
        }
        let auditFinding = auditFindings[finding.audit.id];

        if (auditFinding) {
          auditFinding.findings.push(finding);
        } else {
          auditFindings[finding.audit.id] = {
            audit: finding.audit,
            findings: [finding]
          };
        }
      });
      return {
        node,
        findings: nodeFindings,
        audits: auditFindings
      }
    }
  },
  methods: {
    selectNode(node) {
      this.selectedNodeUrl = node.url;
      this.$forceUpdate();
    },
    highlightFinding(findingId, state) {
      this.highlightedFindingId = state ? findingId : "";
    },
    addFinding(finding) {
      finding.auditId = "";
      finding.stepId = "";
      this.project.findings.push(finding);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: this.project.findings})
          .then(res => {
            console.debug(res);
          });

    },
    removeFinding(id) {
      let index = this.project.findings.findIndex(f => f.id === id);
      this.project.findings.splice(index, 1);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: this.project.findings})
          .then(res => {
            console.debug(res);
          });
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      let node = this.project.netNodes.find(n => n.id === this.$route.params.id);
      if (node && node.url) {
        this.selectedNodeUrl = node.url;
      } else {
        this.$router.push('/findings');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  position: relative;
  width: calc(100% - 1.5rem);

  i.fa-minus-circle {
    position: absolute;
    right: -1.5rem;
    top: .3rem;
    cursor: pointer;
  }

  &::after {
    content: "";
    display: table;
    clear: bottom;
  }
}
</style>